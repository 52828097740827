.breadcrumb_bg {
	@include background("../img/breadcrumb.png");
}

.breadcrumb {
	position: relative;
	z-index: 1;
	text-align: center;
	.breadcrumb_iner {
		height: 450px;
		width: 100%;
		display: table;

		.breadcrumb_iner_item {
			display: table-cell;
			vertical-align: middle;

			h2 {
				color: $white_color;
				font-size: 60px;
				font-weight: 600;
				margin-bottom: 10px;
				text-transform: uppercase;

				@media #{$small_mobile} {
					font-size: 35px;
				}

				@media #{$large_mobile} {
					font-size: 35px;
				}

				@media #{$tab_device} {
					font-size: 40px;
				}

				@media #{$medium_device} {}
			}

			p {
				font-size: 15px;
				color: $white_color;
			}

			span {
				margin: 0px 5px;
				font-size: 12px;
			}

		}
	}

}

.breadcrumb {
	margin-bottom: 0px !important;
}