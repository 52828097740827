// variable scss
@import "variables";

// mixin scss
@import "mixins";
@import "extends";
// default scss
@import "common";

// button scss
@import "button";

@import "blog";
@import "single_blog";
// body scss
@import "menu";
@import "banner";
@import "popular_cource";
@import "shedule_part";
@import "our_feature";
@import "about_part";
@import "client_review";
@import "become_member";
@import "cource_details";
@import "gallery_part";
@import "footer";

@import "elements";
@import "blog_part";
@import "contact";

// breadcrumb scss
@import "breadcrumb";














@import "footer";


