/**************menu part start*****************/
.main_menu {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 999;
	.navbar-brand {
		padding: 0rem !important;
		background-image: url(../img/logo_bg.png);
		background-position: center;
		background-size: cover;
		width: 120px;
		height: 130px;
		text-align: center;
		line-height: 120px;
		margin-top: -1px;
		@media #{$tab}{
			width: 80px;
			height: 90px;
			line-height: 70px;
		}
		@media #{$medium_device}{
			width: 90px;
			height: 100px;
			line-height: 80px;
		}
		img{
			max-width: 82px;
			@media #{$tab}{
				max-width: 60px;
			}
			@media #{$medium_device}{
				max-width: 70px;
			}
		}
	}

	.navbar {
		padding: 0px;
	}
	.navbar-toggler{
		position: relative;
		z-index: 1;
		
	}
	.navbar-toggler-icon {
		background-image: url(../img/menu_icon.png);
	}
	.main-menu-item {
		text-align: right;
		justify-content: right;
		@media #{$medium_device}{
			padding-left: 25px;
		}
		ul {
			li .nav-link{
				color: $menu_color;
				font-size: 15px;
				padding: 30px 24px;
				font-family: $font_stack_1;
				text-transform: uppercase;
				@media #{$medium_device}{
					padding: 0px 16px;
				}
				&:hover{
					color: #ee390f;
				}
				
			}
			.btn_1{
				color: $white-color;
				font-size: 14px;
				padding: 11.5px 33px;
				margin-left: 41px;
			}
			
		}
	}
	
}

.dropdown-menu{
	border: 0px solid rgba(0,0,0,.15) !important;
	background-color: #fafafa;
}

.dropdown {
    .dropdown-menu {
        transition: all 0.5s;
        overflow: hidden;
        transform-origin: top center;
        transform: scale(1,0);
		display: block;
		.dropdown-item{
			font-size: 14px;
			padding: 9px 18px !important;
			color: $black_color !important;
			text-transform: capitalize;
			&:hover{
				color: $btn_bg !important;
			}
		}
    }
    &:hover {
        .dropdown-menu {
            transform: scale(1);
        }
    }
}
@media #{$tab} {
	.navbar-collapse{
		ul {
			li .nav-link{
				color: $black-color !important;
				
			}
		}
	}
	
	.navbar-light .navbar-toggler{
		border-color: transparent;
	}
	.navbar-collapse {
		z-index: 9999 !important;
		position: absolute;
		left: 0;
		top: 71px;
		width: 100%;
		background-color: $white-color;
		text-align: center !important;
		
	}
	.main_menu .main-menu-item{
		text-align: left !important;
		.nav-item{
			padding: 5px 15px !important;
			a{
				padding: 5px 15px !important;
			}
		}
	}
	.navbar-nav{
		align-items: start !important;
	}
	.dropdown {
		.dropdown-menu {
			transform: scale(1,0);
			display: none;
			margin-top: 10px;
		}
		&:hover {
			.dropdown-menu {
				transform: scale(1);
				display: block;
				color: $btn_bg;
			}
		}
		.dropdown-item:hover{
			color: $btn_bg !important;
		}
	}
}

.menu_fixed {
	position: fixed;
	z-index: 9999 !important;
	width: 100%;
	background-color: $base_color;
	box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
	top: 0;
	.btn_1{
		box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
	}
	ul {
		li .nav-link{
			&:hover{
				color: #fff !important;
			}
			
		}
	}
	.navbar-brand {
		height: 90px;
		line-height: 70px;
		background-image: none;
		img{
			max-width: 70px;
		}
	}
}