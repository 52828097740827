/**************** banner part css start ****************/
.banner_part{
    position: relative;
    z-index: 1;
    background-image: url(../img/banner_bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    &:after{
        position: absolute;
        left: 0;
        top: 0;
        content: "";
        width: 100%;
        height: 100%;
        background: #0a0a0a; /* Old browsers */
        background: -moz-linear-gradient(right, rgba(10, 10, 10, .9) 0%, rgba(50, 50, 50, .2) 100%);
        background: -webkit-linear-gradient(right, rgba(10, 10, 10, .9) 0%, rgba(50, 50, 50, .2) 100%);
        background: linear-gradient(to right, rgba(10, 10, 10, .9) 0%, rgba(50, 50, 50, .2) 100%);
        z-index: -1;
    }

    .banner_text{
        display: table;
        width: 100%;
        height: 1080px;
        .banner_text_iner{
            display: table-cell;
            vertical-align: middle;
        }
        @media #{$tab}{
            height: 650px;
            text-align: center;
        }
        @media #{$medium_device}{
            height: 700px;
        }
        p{
            font-size: 16px;
            text-transform: capitalize;
            font-weight: 300;
            color: $white_color;
            margin-bottom: 28px;
            position: relative;
            z-index: 1;
            &:after{
                position: absolute;
                content: "";
                left: 0;
                bottom: -12px;
                width: 100px;
                height: 1px;
                background-color: $white_color;
                @media #{$tab}{
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }
            }
        }
        h1{
            font-size: 100px;
            text-transform: uppercase;
            font-weight: 700;
            margin-bottom: 64px;
            line-height: 120px;
            color: $white_color;
            font-family: $font_stack_1;
            @media #{$small_mobile}{
                font-size: 25px;
                margin-bottom: 15px;
                line-height: 1.3;
            }
            @media #{$large_mobile}{
                font-size: 40px;
                margin-bottom: 15px;
                line-height: 1.3;
            }
            @media #{$tab_device}{
                font-size: 40px;
                margin-bottom: 15px;
                line-height: 1.3;
            }
            @media #{$medium_device}{
                font-size: 40px;
                margin-bottom: 15px;
                line-height: 1.4;
            }
            span{
                color: $btn_bg;
            }
        }
    }
}
  
/**************** hero part css end ****************/
