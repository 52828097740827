$font_stack_1: 'Oswald', sans-serif;
$font_stack_2: 'Anton', sans-serif;

$white_color: #fff;
$black_color: #000;
$menu_color: #fff;
$icon_color: #0c2e60;
$icon_bg: #f0f4f6;
$author_text_color: #556172;



$btn_bg: #ff0000;
$base_color: #ff0000;
$btn_hover: #f5790b;
$section_bg: #f7f7f7;
$section_bg_1: #454545;
$heading_color: #222;
$heading_color2: #ff8b23;
$p_color: #777777;
$font_1: #666666;
$font_2: #646464;
$font_3: #7f7f7f;
$font_4: #8a8a8a;
$font_5: #999999;
$font_6: #666666;
$font_7: #777777;
$font_8: #888888;
$border_color: #edeff2;
$footer_bg: #303030;
$sidebar_bg: #fbf9ff;



$medium_device : 'only screen and (min-width: 992px) and (max-width: 1200px)';
$tab_device:'only screen and (min-width: 768px) and (max-width: 991px)';
$large_mobile: 'only screen and (min-width: 576px) and (max-width: 767px)';
$small_mobile:'(max-width: 576px)';
$tab:'(max-width: 991px)';
$sm_mobile:'only screen and (min-width: 421px) and (max-width: 575px)';
$big_screen:'only screen and (min-width: 1200px) and (max-width: 1440px)';
$extra_big_screen: 'only screen and (min-width: 1200px) and (max-width: 3640px)';
  
  
  