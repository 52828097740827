/******** cloent review css here *********/
.client_review{
    .client_review_single{
        text-align: center;
        padding: 50px 42px;
        border: 2px solid $black_color;
        @media #{$tab}{
            padding: 30px;
        }
        @media #{$medium_device}{
            padding: 30px;
        }
        img{
            border-radius: 50%;
            max-width: 80px;
            margin: 0 auto;
            text-align: center;
        }
        h4{
            font-size: 16px;
            margin-top: 17px;
        }
        p{
           margin-top: 26px; 
           font-size: 16px;
        }
    }
    .owl-dots{
        margin: 0 auto;
        text-align: center;
        margin-top: 75px;
        @media #{$tab}{
            margin-top: 30px;
        }
        @media #{$medium_device}{
            margin-top: 30px;
        }
        .owl-dot{
            display: inline-block;
            height: 14px;
            width: 14px;
            background-color: #eeeeee !important;
            margin: 0 5px;
        }
        .active {
            background-color: $base_color !important;
        }
    }
}
/******** cloent review css end *********/